<template>
    <f7-view>
        <f7-page>
            <f7-navbar title="Add user to project" :subtitle="forceProject ? projectInFocusInfo.name : null">
                <f7-nav-right>
                    <f7-link popup-close>Cancel</f7-link>
                </f7-nav-right>
            </f7-navbar>
            <f7-list class="no-margin-top">
                <f7-list-item divider :title="`1.- ${forceProject ? 'Project' : 'Select project'}`"></f7-list-item>
                <f7-list-item
                    v-if="!forceProject"
                    title="Project"
                    smart-select
                    :smart-select-params="{
                        searchbar: { removeDiacritics: true },
                        closeOnSelect: true,
                        virtualList: true,
                        searchbarPlaceholder: 'Search project',
                    }"
                >
                    <select @change="setNewProject($event.target.value)" name="projectox">
                        <option value="">Select a project</option>
                        <option v-for="project in projectsToShow" :key="project.id" :value="project.id">
                            {{ project.name }}
                            {{ project.cemail ? `(${project.cemail})` : "" }}
                        </option>
                    </select>
                </f7-list-item>
                <f7-list-item v-else :title="projectInFocusInfo.name"></f7-list-item>
                <template v-if="newPrivsProject.projectid">
                    <f7-list-item divider title="2.- Indicate the origin"></f7-list-item>
                    <f7-list-item
                        :title="projectInFocusInfo.grouper || 'Institutions'"
                        smart-select
                        ref="estesmartref"
                        :smart-select-params="{
                            searchbar: { removeDiacritics: true },
                            closeOnSelect: true,
                            virtualList: true,
                            searchbarPlaceholder: `Search ${projectInFocusInfo.grouper || 'Institutions'}`,
                        }"
                    >
                        <select @change="newPrivsProject.institutionid = $event.target.value" name="institutionx">
                            <option value="">Select origin</option>
                            <option v-for="oneinstit in getInstitutions()" :key="oneinstit.id" :value="oneinstit.id">
                                {{ oneinstit.name }}
                            </option>
                        </select>
                    </f7-list-item>
                    <template v-if="newPrivsProject.institutionid">
                        <f7-list-item divider title="3.- Set Project level privileges (optional)"></f7-list-item>
                        <f7-list-item
                            v-for="(unpriv, index) in projectprofiles"
                            :key="`${unpriv.id}_${index}`"
                            :title="unpriv.id === 'full' ? 'write' : unpriv.id"
                            :footer="unpriv.descr"
                            :class="{
                                disabled:
                                    (unpriv.reqby && newPrivsProject.projectPrivs[unpriv.reqby]) ||
                                    (unpriv.reqs && !newPrivsProject.projectPrivs[unpriv.reqs]) ||
                                    (unpriv.id === 'phi' &&
                                        newPrivsProject.projectPrivs.admin &&
                                        (newPrivsProject.projectPrivs.admin['project-admin'] === true ||
                                            newPrivsProject.projectPrivs.admin.full === true)) ||
                                    (unpriv.id !== 'project-admin' &&
                                        newPrivsProject.projectPrivs['project-admin'] === true),
                            }"
                        >
                            <f7-toggle
                                slot="after"
                                @toggle:change="creaPrivsEnProject($event, unpriv.id)"
                                color="teal"
                                :checked="newPrivsProject.projectPrivs[unpriv.id]"
                            ></f7-toggle>
                        </f7-list-item>

                        <f7-list-item
                            divider
                            :title="`4.- Set ${
                                projectInFocusInfo.grouper || 'institutions'
                            } level privileges (optional)`"
                        ></f7-list-item>
                        <f7-list-item title="read" footer="User can read entities from this institution">
                            <f7-toggle slot="after" color="purple" checked disabled></f7-toggle>
                        </f7-list-item>
                        <f7-list-item
                            v-for="(unpriv, index) in institutionProfiles"
                            :key="`${unpriv.id}_${index}`"
                            :title="unpriv.id === 'create' ? 'write' : unpriv.id"
                            :footer="unpriv.descr"
                        >
                            <f7-toggle
                                slot="after"
                                @toggle:change="creaPrivenInstitution($event, unpriv.id)"
                                color="purple"
                                :checked="newPrivsProject.instiPrivs[unpriv.id]"
                                :disabled="
                                    (unpriv.id === 'create' && newPrivsProject.projectPrivs.full) ||
                                    (unpriv.id === 'download' && newPrivsProject.projectPrivs.download) ||
                                    (unpriv.id === 'phi' &&
                                        (newPrivsProject.instiPrivs.update || newPrivsProject.instiPrivs.create)) ||
                                    (unpriv.id === 'phi' && newPrivsProject.projectPrivs.phi) ||
                                    newPrivsProject.projectPrivs['project-admin'] === true
                                "
                            ></f7-toggle>
                        </f7-list-item>
                    </template>
                </template>
            </f7-list>
            <f7-block>
                <f7-row>
                    <f7-col><f7-button class="cancelButton" popup-close large>Cancel</f7-button></f7-col>
                    <f7-col>
                        <f7-button color="teal" @click="darprivilegios()" large raised fill>Grant Privileges</f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
        </f7-page>
    </f7-view>
</template>
<script>
    import { mapGetters, mapState } from "vuex";
    import { commonmixin } from "../mixins/common";

    export default {
        mixins: [commonmixin],
        props: ["uid", "rolesByProject", "forceProject"],
        data() {
            return {
                newPrivsProject: {
                    projectid: null,
                    institutionid: null,
                    instiPrivs: {},
                    projectPrivs: {},
                },
            };
        },
        computed: {
            ...mapState(["profiles", "user"]),
            ...mapGetters(["projects", "privxInfo"]),
            projectInFocus() {
                return this.$store.state.projectInFocus || this.$store.state.adminprojectFocus;
            },
            projectInFocusInfo() {
                return this.$store.state.projects[this.projectInFocus] || {};
            },
            projectsToShow() {
                return this.projects.filter((projectinf) => {
                    return !this.rolesByProject[projectinf.id];
                });
            },
            projectprofiles() {
                let glbpirvs = Object.values(this.profiles.project || {});

                if (!this.projectInFocusInfo.isDeidentificationEnabled) {
                    glbpirvs = glbpirvs.filter((p) => p.id !== "phi");
                }

                return glbpirvs
                    .sort((a, b) => {
                        if (a.o > b.o) {
                            return 1;
                        } else if (a.o < b.o) {
                            return -1;
                        } else {
                            return 0;
                        }
                    })
                    .filter((p) => {
                        return !p.hidden && !(p.id === "project-admin" && !this.privxInfo.admin);
                    });
            },
            institutionProfiles() {
                let instiprivs = Object.values(this.profiles.institution || {});

                if (!this.projectInFocusInfo.isDeidentificationEnabled) {
                    instiprivs = instiprivs.filter((p) => p.id !== "phi");
                }

                return instiprivs
                    .sort((a, b) => {
                        if (a.o > b.o) {
                            return 1;
                        } else if (a.o < b.o) {
                            return -1;
                        } else {
                            return 0;
                        }
                    })
                    .filter((p) => !p.hidden);
            },
        },
        mounted() {
            this.newPrivsProject.projectid = this.forceProject;
        },
        methods: {
            setNewProject(neovalue) {
                this.newPrivsProject.institutionid = "";
                this.newPrivsProject.instiPrivs = {};
                this.newPrivsProject.projectPrivs = {};
                if (this.$refs.estesmartref) {
                    //  console.log('ES',this.$refs.estesmartref.f7SmartSelect)
                    this.$refs.estesmartref.f7SmartSelect.setValue("");
                }
                this.newPrivsProject.projectid = neovalue;
            },
            getInstitutions() {
                return this.$store.state.projects[this.newPrivsProject.projectid]
                    ? Object.values(this.$store.state.projects[this.newPrivsProject.projectid].institutions).filter(
                          (inst) => {
                              return !inst.inactive;
                          },
                      )
                    : [];
            },
            async darprivilegios() {
                try {
                    //    console.log(this.newPrivsProject);
                    if (!this.newPrivsProject.projectid) {
                        throw new Error("You must provide a project id");
                    }
                    if (!this.newPrivsProject.institutionid) {
                        throw new Error("You must provide an institution id");
                    }

                    this.$f7.dialog.preloader("Granting privileges");
                    let tosave = {
                        uid: this.uid,
                        pid: this.newPrivsProject.projectid,
                        admin: this.newPrivsProject.projectPrivs,
                        prv: {},
                    };
                    let elprivintis = {
                        id: this.newPrivsProject.institutionid,
                        det: this.newPrivsProject.instiPrivs,
                    };
                    tosave.prv[this.newPrivsProject.institutionid] = elprivintis;
                    console.log("VA A GUARDAR", tosave, `en ${this.uid}${this.newPrivsProject.projectid}`);
                    let lasupdates = {};
                    lasupdates[`privsByProject/${this.uid}${this.newPrivsProject.projectid}`] = tosave;
                    lasupdates[`privsByProject_req/${this.uid}${this.newPrivsProject.projectid}/granted`] = true;
                    await this.$firebase.database().ref().update(lasupdates);
                    this.$store.commit("setWhatTo", {
                        what: "forceUsersReload",
                        to: true,
                    });

                    if (this.uid == this.user.uid) {
                        let privsSnap = await this.$firebase
                            .database()
                            .ref(`privsByProject/${this.uid}${this.newPrivsProject.projectid}`)
                            .once("value");

                        if (privsSnap.exists()) {
                            let privs = privsSnap.val() || {};
                            let localUser = Object.assign({}, this.user);
                            localUser.privsByProject[this.newPrivsProject.projectid] = privs;
                            this.$store.commit("setObjTo", { what: "user", to: localUser });
                        }
                    }

                    this.$f7.dialog.close();
                    this.$emit("neoPrivsProject", tosave);
                    this.avisaOk();
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
            creaPrivenInstitution(nuevovalor, privid) {
                if (!nuevovalor) {
                    nuevovalor = null;
                }
                this.$set(this.newPrivsProject.instiPrivs, privid, nuevovalor);

                if (privid === "create" && !nuevovalor) {
                    this.$set(this.newPrivsProject.instiPrivs, "update", null);
                } else if (privid === "create" && nuevovalor) {
                    this.$set(this.newPrivsProject.instiPrivs, "phi", true);
                }
            },
            creaPrivsEnProject(nuevovalor, privid) {
                if (!nuevovalor) {
                    nuevovalor = null;
                }
                this.$set(this.newPrivsProject.projectPrivs, privid, nuevovalor);

                if (privid === "project-admin" && nuevovalor === true) {
                    // to do: set all other privileges to true
                    this.$set(this.newPrivsProject.projectPrivs, "project-admin", true);
                    this.$set(this.newPrivsProject.projectPrivs, "read", true);
                    this.$set(this.newPrivsProject.projectPrivs, "download", true);
                    this.$set(this.newPrivsProject.projectPrivs, "full", true);
                    this.$set(this.newPrivsProject.projectPrivs, "phi", true);
                    this.$set(this.newPrivsProject.projectPrivs, "accounts", true);

                    this.$set(this.newPrivsProject.instiPrivs, "download", true);
                    this.$set(this.newPrivsProject.instiPrivs, "create", true);
                    this.$set(this.newPrivsProject.instiPrivs, "update", true);
                } else if (privid === "full" && nuevovalor === true) {
                    this.$set(this.newPrivsProject.projectPrivs, "phi", true);

                    this.$set(this.newPrivsProject.instiPrivs, "phi", true);
                    this.$set(this.newPrivsProject.instiPrivs, "create", true);
                    this.$set(this.newPrivsProject.instiPrivs, "update", true);
                } else if (privid === "read" && !nuevovalor) {
                    this.$set(this.newPrivsProject.projectPrivs, "phi", null);
                } else if (privid === "download" && nuevovalor === true) {
                    this.$set(this.newPrivsProject.instiPrivs, "download", true);
                } else if (privid === "phi" && nuevovalor === true) {
                    this.$set(this.newPrivsProject.instiPrivs, "phi", true);
                }

                console.log("Privs after change", this.newPrivsProject);
            },
        },
    };
</script>
<style></style>
