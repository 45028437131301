<template>
    <f7-view name="modifyentity">
        <f7-page>
            <f7-navbar subtitle="Edit Entity Data" :title="`Entity data name`"></f7-navbar>

            <f7-block-title class="margin">Entity data being modified</f7-block-title>
            <entity-card
                :preg="preg"
                :localentity="nuevoValor"
                @selectionaOption="selectionaOption"
                @setDate="setDate"
                @setprimitive="setprimitive"
            ></entity-card>
            <f7-block>
                <f7-row>
                    <f7-col><f7-button class="cancelButton" popup-close large>Cancel</f7-button></f7-col>
                    <f7-col>
                        <f7-button color="teal" @click="savechanges()" large raised fill>Save changes</f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
        </f7-page>
    </f7-view>
</template>
<script>
    import { commonmixin } from "../mixins/common";
    import entityCard from "../components/entity_card.vue";

    export default {
        mixins: [commonmixin],
        components: { entityCard },
        props: ["preg", "entity", "projectID"],
        data() {
            return {
                nuevoValor: null,
            };
        },
        created() {
            if (this.entity.entityData && this.entity.entityData[this.preg.id]) {
                this.nuevoValor = JSON.parse(JSON.stringify(this.entity.entityData[this.preg.id]));
            }
        },
        methods: {
            selectionaOption({ altid, checked }) {
                if (!this.nuevoValor) {
                    this.nuevoValor = {};
                }
                if (!checked) {
                    this.$delete(this.nuevoValor, altid);
                } else {
                    this.$set(this.nuevoValor, altid, checked);
                }
            },
            setDate({ fechfix }) {
                this.nuevoValor = fechfix;
            },
            setprimitive({ value }) {
                this.nuevoValor = value;
            },
            async savechanges() {
                try {
                    // console.log('esta afectando la pregid', this.preg.id)
                    // return false;
                    // console.log('vamos a crear con nuevo valor ',this.nuevoValor);
                    //createEntity
                    this.$f7.dialog.preloader("Updating...");
                    let supayload = {
                        projectid: this.projectID,
                        entityid: this.entity.id,
                        institutionid: this.entity.instid,
                        itemid: this.preg.id,
                        neovalue: this.nuevoValor || null,
                    };
                    //  console.log('supayload',supayload);
                    let datous = await this.commonExecute(supayload, "v2_updateEntityDataValue", false);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    //   console.log(datous.payload)
                    this.$f7.dialog.close();
                    this.avisaOk();
                    this.$emit("procesaCambio", {
                        pregid: this.preg.id,
                        value: this.nuevoValor,
                    });
                } catch (error) {
                    this.$f7.dialog.close();
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            },
        },
    };
</script>
<style></style>
