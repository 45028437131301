import { render, staticRenderFns } from "./privs_proj_crear.vue?vue&type=template&id=5ea8ba59"
import script from "./privs_proj_crear.vue?vue&type=script&lang=js"
export * from "./privs_proj_crear.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports